<template>
	<div class="">
		<Header class="sticky top-0 z-10"></Header>
		<NuxtPage class="z-0"></NuxtPage>
		<Discord></Discord>
		<Spotify></Spotify>
		<Footer></Footer>
	</div>
</template>

<script>
import Header from "~/components/layout/Header.vue";
import Discord from "~/components/layout/Discord.vue";
import Footer from "~/components/layout/Footer.vue";
import Spotify from "~/components/layout/Spotify.vue";

export default {
	name: "NuxtLayout",
	components: {Spotify, Footer, Discord, Header}
}
</script>

<style>
body {
	font-family: 'Barlow Semi Condensed';
}
</style>
